<template>
  <div style="background:rgb(249, 249, 249);height:178vw;width:100%">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">我的订单</p>
    </div>
    <div class="nav">
      <van-tabs
        v-model="active"
        sticky
        title-active-color="#5ca5ff"
        color="#5ca5ff"
        @click="onClick"
      >
        <!-- <van-tab title="预约挂号(未支付)">
          <div class="content-box">
            <div class="content" v-for="(item, index) in nopay" :key="index">
              <p class="content-title">
                <strong>{{ item.Department }}</strong>

                <em style="color: orange;">未支付</em>
              </p>
              <p><em class="gray">医生姓名:</em>{{ item.Doctor }}</p>
              <p><em class="gray"> 就诊医院:</em>{{ item.HospitalName }}</p>
              <p><em class="gray">就诊人:</em>{{ item.PatName }}</p>
              <p><em class="gray">就诊时间:</em>{{ item.AdmitDate }}</p>
            </div>
          </div>
        </van-tab> -->
        <van-tab title="挂号订单">
          <div class="content-box">
            <div class="content" v-for="(item, index) in order" :key="index">
              <p class="content-title">
                <strong>{{ item.Department }}</strong>
                <em style="color: orange;">{{ item.Status }}</em>
              </p>
              <p><em class="gray">医生姓名:</em>{{ item.Doctor }}</p>
              <p><em class="gray"> 就诊医院:</em>{{ item.HospitalName }}</p>
              <p><em class="gray">就诊人:</em>{{ item.PatName }}</p>
              <p><em class="gray">就诊时间:</em>{{ item.customTime }}</p>
              <button
                class="btn"
                v-if="index === checkState || item.Status !== '退号'"
                @click="tuihao(index, item)"
              >
                退号
              </button>
            </div>
          </div>
        </van-tab>
        <van-tab title="问诊订单">
          <div class="content-box">
            <div class="content" v-for="(item, index) in list" :key="index">
              <p class="content-title-wenzhen">
                <strong>{{ item.title }}</strong>
              </p>
              <p><em class="gray">订单编号:</em>{{ item.orderid }}</p>
              <p>
                <em class="gray"> 支付金额:</em>￥<i class="money">{{
                  item.amount
                }}</i>
              </p>
              <p><em class="gray">支付日期:</em>{{ item.updatetime }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import {
  getOrder,
  getFamilyOrderDetail,
  // getFamilyOrderJiuzhen,
  getFamily,
  getOrderExit
  // getOrderDetails
} from '../services/home'
import instance from '../utils/request.js'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      order: [],
      current: 0,
      active: 0,
      list: [],
      nopay: [],
      checkState: null,
      orderList1: []
    }
  },
  async created() {
    const fojz = await getFamily(this.$route.query.user_id)
    var now = new Date()
    var future = new Date()
    future.setDate(future.getDate() + 7)
    function plus0(number) {
      return number < 10 ? '0' + number : number
    }
    const wezhen = fojz.data.data.rows
    let questRes = []

    for (var i = 0; i < wezhen.length; i++) {
      var orderListRes = await getFamilyOrderDetail(
        '<Request>' +
          '<TradeCode>1104</TradeCode>' +
          '<ExtOrgCode></ExtOrgCode>' +
          '<ClientType></ClientType>' +
          '<HospitalId></HospitalId>' +
          '<ExtUserID>wechat002</ExtUserID>' +
          '<CardType></CardType>' +
          '<PatientCard></PatientCard>' +
          '<PatientID>' +
          wezhen[i].patient_id +
          '</PatientID>' +
          '<StartDate>' +
          (now.getFullYear() - 1) +
          '-' +
          plus0(now.getMonth() + 1) +
          '-' +
          plus0(now.getDate()) +
          '</StartDate>' +
          '<EndDate>' +
          future.getFullYear() +
          '-' +
          plus0(future.getMonth() + 1) +
          '-' +
          plus0(future.getDate()) +
          '</EndDate>' +
          '<TransactionId></TransactionId>' +
          '<AdmNo></AdmNo>' +
          '</Request>'
      )
      if (orderListRes.data.Response.ResultCode === '0') {
        var orderList = orderListRes.data.Response.Orders.Order
        if (toString.call(orderList) === '[object Array]') {
          questRes = [...orderList, ...questRes]
        } else {
          questRes.push(Object.assign({}, orderList))
        }
      }
    }
    // console.log(questRes)
    questRes = questRes.map(item => {
      const { RegDate } = item
      item.customTime = RegDate.replace('^', ' ')
      item.RegDate = new Date(item.RegDate.replace('^', ' ')).getTime()
      return item
    })

    const resSome = questRes.sort((a, b) => {
      // console.log(a, b)
      return b.RegDate - a.RegDate
    })

    this.order = resSome

    // 问诊订单
    const res = await getOrder({ user_id: this.$route.query.user_id })
    var result = res.data
    result.forEach(e => {
      const time = this.$moment(parseInt(e.createtime * 1000)).format(
        'YYYY-MM-DD hh:mm:ss'
      )
      e.updatetime = time
    })
    const dingdan = result.sort(function(a, b) {
      return b.createtime - a.createtime
    })
    this.list = dingdan
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onClick(name, title) {
      if (title === '挂号订单') {
        if (this.order.length === 0) {
          Dialog.alert({
            message: '暂无挂号订单'
          }).then(() => {})
        }
      }
      if (title === '问诊订单') {
        if (this.list.length === 0) {
          Dialog.alert({
            message: '暂无问诊订单'
          }).then(() => {})
        }
      }
    },
    tuihao(index, item) {
      const { Status: status } = item
      if (status === '正常') {
        Dialog.confirm({
          message: '是否退号？'
        })
          .then(() => {
            getOrderExit(
              '<Request>' +
                '<TradeCode>1003</TradeCode>' +
                '<ExtOrgCode></ExtOrgCode>' +
                '<ClientType></ClientType>' +
                '<HospitalId></HospitalId>' +
                '<ExtUserID>wechat002</ExtUserID>' +
                '<AdmNo>' +
                this.order[index].AdmNo +
                '</AdmNo>' +
                '<TransactionId></TransactionId>' +
                '<RefundType></RefundType>' +
                '</Request>'
            ).then(res => {
              if (res.data.Response.ResultCode === '0') {
                Toast(res.data.Response.ResultContent)
                this.order[index].Status = '退号'
                instance({
                  method: 'POST',
                  url: 'addons/ask/Order/getNoRegister',
                  data: {
                    user_id: this.$route.query.user_id
                  }
                })
                  .then(result => {
                    console.log(result, '取消挂号-减积分请求结果')
                  })
                  .catch(err => {
                    console.error('取消挂号-减积分请求出现错误', err)
                  })
              } else {
                Toast(res.data.Response.ResultContent)
              }
            })
          })
          .catch(() => {
            //
          })
      } else {
        // this.order.splice(index, 1)
        this.checkState = null
      }
    }
  }
}
</script>

<style scoped>
.nav {
  position: absolute;
  top: 44px;
  width: 100%;
  height: 9vw;
}
.nav-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.bgColors {
  color: #5ca5ff;
  border-bottom: 2px solid #5ca5ff;
  padding-bottom: 2vw;
}
.content-box {
  position: absolute;
  top: 12vw;
  width: 100%;
}
.content {
  width: 87vw;
  padding: 5px 14px;
  overflow: hidden;
  background: #ffffff;
  border: 0.1px solid #eeeeee;
  border-radius: 10px;
  margin-left: 3vw;
  margin-bottom: 3vw;
  margin-top: 2vw;
}
.content-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1px solid #eeeeee;
  padding: 1px 0 9px 0;
  margin: 10px 0;
  line-height: 22px;
}
.content-title-wenzhen {
  width: 100%;
  border-bottom: 0.1px solid #eeeeee;
  padding: 1px 0 9px 0;
  margin: 10px 0;
  line-height: 22px;
}
.content-title strong {
  font-weight: bold;
}
em {
  font-style: normal;
}
.gray {
  color: #666666;
}
.money {
  font-weight: bold;
  font-style: normal;
}
.btn {
  width: 100px;
  height: 36px;
  border-radius: 17px;
  border: 1px solid #d8d8d8;
  font-size: 12px;
  font-weight: 400;
  background: #5ca5ff;
  color: white;
  float: right;
  margin: 5px;
}
.back {
  z-index: 100;
}
/deep/ .van-tabs__line {
  background-color: #5ca5ff !important;
}
/deep/ .van-tab {
  font-weight: bolder;
}
</style>
